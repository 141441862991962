.message {
  display: flex;
  margin-bottom: 8px;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.4;
  max-width: 90%;
  font-family: var(--font-family);
  transition: all 0.3s ease;
  min-height: 72px;
}

.message.user {
  align-self: flex-end;
  text-align: right;
}

.message.assistant {
  align-self: flex-start;
  text-align: left;
}

.message.loading {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.message.loading .text-background {
  min-width: 60px;
  min-height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message.assistant.loading {
  align-self: flex-start;
}

.message.user.loading {
  align-self: flex-end;
}

.message-text {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  border-radius: var(--border-radius);
  word-break: break-word;
  justify-content: center;
}

.text-background {
  padding: 8px 12px;
  border-radius: var(--border-radius);
  word-break: break-word;
  font-size: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-background.user {
  background-color: var(--primary-color);
  color: var(--text-color-user);
  text-align: right;
}

.text-background.assistant {
  background-color: var(--secondary-color);
  color: var(--text-color-assistant);
  text-align: left;
}

.message-timestamp {
  position: relative;
  font-size: 11px;
  opacity: 0.7;
  white-space: nowrap;
  bottom: 10px;
  margin-bottom: 2px;
  color: var(--text-color-user);
}

.retry-button {
  margin-top: 4px;
  font-size: 12px;
  color: var(--error-color);
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: var(--error-bg);
}

.loading-container {
  display: flex;
  padding: 8px;
  width: 100%;
  justify-content: center;
}

.loading-dots {
  display: flex;
  gap: 6px;
  align-items: center;
}

.loading-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor; /* This will inherit from parent text color */
  animation: pulse 1.2s ease-in-out infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0.4);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .message {
    max-width: 95%;
  }
}