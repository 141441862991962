.messages-list {
  flex-grow: 1;
  overflow-y: auto;
  list-style: none;
  padding: 0px;
  padding-top: 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 8px;
  transition: all 0.3s ease;
  background-color: transparent;
}

.messages-list-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: min-content;
}

.spinner-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: var(--text-color);
}