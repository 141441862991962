.date-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.date-divider::before,
.date-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--text-color);
  opacity: 0.2;
}

.date-divider-text {
  padding: 0 10px;
  font-size: 12px;
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 10px
}