:root {
  /* Light mode colors */
  --primary-color-light: #17DAFD;
  --secondary-color-light: #6590D5;
  --background-color-light: rgba(255, 255, 255, 0.65);
  --background-blur-light: rgba(255, 255, 255, 0.85);
  --text-color-light: #2C2C2C;
  --text-color-user-light: #000000;
  --text-color-assistant-light: #000000;
  --placeholder-color-light: #68dbf2;
  --error-color-light: #fb6c6c;
  --error-bg-light: rgba(255, 229, 229, 0.9);

  /* Dark mode colors - Updated for better transparency */
  --primary-color-dark: #00A3D9;
  --secondary-color-dark: #3A6BC5;
  --background-color-dark: rgba(20, 20, 20, 0.75);
  --background-blur-dark: rgba(20, 20, 20, 0.85);
  --text-color-dark: #ffffff;
  --text-color-user-dark: #ffffff;
  --text-color-assistant-dark: #ffffff;
  --placeholder-color-dark: #4DB5D0;
  --error-color-dark: #FF8080;
  --error-bg-dark: rgba(255, 229, 229, 0.2);

  /* Default to light mode */
  --primary-color: var(--primary-color-light);
  --secondary-color: var(--secondary-color-light);
  --background-color: var(--background-color-light);
  --background-blur: var(--background-blur-light);
  --text-color: var(--text-color-light);
  --text-color-user: var(--text-color-user-light);
  --text-color-assistant: var(--text-color-assistant-light);
  --placeholder-color: var(--placeholder-color-light);
  --error-color: var(--error-color-light);
  --error-bg: var(--error-bg-light);

  /* Enhanced blur effect */
  --blur-effect: blur(15px);
  --blur-effect-strong: blur(20px);

  /* Fonts */
  --font-family: "Poppins", sans-serif;

  /* Sizes */
  --border-radius: 30px;

  /* Effects */
  --blur-effect: blur(12px);
  --blur-effect-background-color: rgba(160, 160, 160, 0.1);

  /* Safe Area Insets */
  --sat: env(safe-area-inset-top, 0);
  --sar: env(safe-area-inset-right, 0);
  --sab: env(safe-area-inset-bottom, 0);
  --sal: env(safe-area-inset-left, 0);

  scroll-behavior: smooth;
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: var(--primary-color-dark);
    --secondary-color: var(--secondary-color-dark);
    --background-color: var(--background-color-dark);
    --background-blur: var(--background-blur-dark);
    --text-color: var(--text-color-dark);
    --text-color-user: var(--text-color-user-dark);
    --text-color-assistant: var(--text-color-assistant-dark);
    --placeholder-color: var(--placeholder-color-dark);
    --error-color: var(--error-color-dark);
    --error-bg: var(--error-bg-dark);
  }
}


/* Smooth Transitions */
body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background-color: transparent;
  transition: color 0.3s ease;
}

/* Utility Classes */
.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Scrollbar */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  :root {
    --border-radius: 30px;
  }
}

/* Utility classes for text colors */
.text-primary { color: var(--primary-color); }
.text-secondary { color: var(--secondary-color); }
.text-error { color: var(--error-color); }

/* Utility classes for background colors */
.bg-primary { background-color: var(--primary-color); }
.bg-secondary { background-color: var(--secondary-color); }
.bg-error { background-color: var(--error-bg); }