.chatbot-widget-container {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    color: white;
    -webkit-overflow-scrolling: touch;
  }
  
  .chat-window {
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 0 0.25px var(--primary-color);
    background-color: var(--background-color);
    color: var(--text-color);
    backdrop-filter: var(--blur-effect);
    -webkit-backdrop-filter: var(--blur-effect);
    transform: translate3d(0, 0, 0);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.2, 0, 0.3, 0.2), opacity 0.3s ease;
    z-index: 999;
    overflow: hidden;
  }
  
  .chat-window.open {
    transform: translateY(0);
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .chatbot-widget-container {
      padding: 0px;
    }
  
    .chat-window {
      border-radius: var(--border-radius);
      border-radius: 0px;
    }
  }