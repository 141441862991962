.chat-header {
  padding: 1px 16px;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: absolute;
  top: var(--sat);
  left: 0;
  right: 0;
  backdrop-filter: var(--blur-effect);
  -webkit-backdrop-filter: var(--blur-effect);
  z-index: 2;
  background: rgba(var(--primary-color-rgb), 0.85);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.company-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  border-radius: 100%;
  background-color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
}

.company-logo {
  height: 40px;
  width: 40px;
  display: block;
  margin: 0 auto;
}

.chat-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  color: var(--text-color);
}

.chat-close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.chat-close-button:active {
  transform: scale(0.95);
}

.chat-header-title {
  font-size: 16px;
  font-family: var(--font-family);
  color: var(--text-color);
  font-weight: 500;
  margin: 0;
}